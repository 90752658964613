export enum ApplicationModuleSections {
	ACCOUNT = 'account',
	WELCOME = 'welcome',
	TODAYS_OVERVIEW = 'todays-overview',
	ANALYZE_HISTORY = 'analyze-history',
	SALES_REPORTS = 'sales-reports',
	DETAIL = 'detail',
	SETTINGS = 'settings',
	SESSIONS_HISTORY = 'sessions-history',
	SAFE_DEPOSIT = 'safe-deposit',
	GLOBAL_OVERVIEW = 'global-overview',
	HISTORY_LOGS = 'history-logs',
	DTT_EXPORT = 'dtt-export',
	DTT_EXPORT_SOURCED = 'dtt-export-sourced',
	ACCOUNTING = 'accounting',
	PERFORMANCE = 'performance',
	PERFORMANCE_SOURCED = 'performance-sourced',
	ERROR = 'error'
}

export enum LocaleKey {
	LANGUAGE = 'language',
	REGION = 'region'
}
