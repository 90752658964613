/* eslint-disable @typescript-eslint/no-unused-vars */
import { Action, createReducer, on } from '@ngrx/store';

import * as Actions from './actions';
import { SYNTHESIS_REPORT_INITIAL_STATE, SynthesisReportState } from './state';

export const synthesisReportReducer = createReducer<SynthesisReportState, Action>(
	SYNTHESIS_REPORT_INITIAL_STATE,
	on(Actions.getSynthesisReport, (state, _action) => ({ ...state, isLoading: true, error: undefined })),

	on(Actions.getSynthesisReportFail, (state, _action) => ({
		...state,
		isLoading: false,
		error: true,
	})),

	on(Actions.getSynthesisReportSuccess, (state, action) => ({
		...state,
		generalSynthesisReport: action.data,
		isLoading: false,
		error: false,
	})),
	on(Actions.getSynthesisVatReport, (state, _action) => ({ ...state, isLoading: true, error: undefined })),

	on(Actions.getSynthesisVatReportFail, (state, _action) => ({
		...state,
		isLoading: false,
		error: true,
	})),

	on(Actions.getSynthesisVatReportSuccess, (state, action) => ({
		...state,
		vatSynthesisReport: action.data,
		isLoading: false,
		error: false,
	})),
);
