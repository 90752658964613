import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ApplicationModuleSections, DEFAULT_INITIAL_ROUTE, REDIRECT_URL } from './core/entity';
import { AuthGuard } from './core/services/auth-guard/auth.guard';
import { FeatureGuard } from './core/services/feature-guard/feature.guard';
import { PermissionGuard } from './core/services/permission-guard/permission.guard';
import { RedirectComponent } from './featured/account/dumb-components/redirect/redirect.component';

const routes: Routes = [
	{
		path: `${ApplicationModuleSections.ACCOUNT}`,
		loadChildren: () => import('./featured/account/account.module').then((m) => m.AccountModule)
	},
	{
		path: `${ApplicationModuleSections.GLOBAL_OVERVIEW}`,
		loadChildren: () => import('./featured/global-overview/global-overview.module').then((m) => m.GlobalOverviewModule),
		canActivate: [AuthGuard, PermissionGuard]
	},
	{
		path: `${ApplicationModuleSections.SALES_REPORTS}`,
		loadChildren: () => import('./featured/reports/reports.module').then((m) => m.ReportsModule),
		canActivate: [AuthGuard, FeatureGuard, PermissionGuard],
		data: { canActivateFeatureFlag: 'reports' }
	},
	{
		path: `${ApplicationModuleSections.DETAIL}`,
		loadChildren: () => import('./featured/dashboard-item-detail/dashboard-item-detail.module').then((m) => m.DashboardItemDetailModule),
		canActivate: [AuthGuard, PermissionGuard]
	},
	{
		path: `${ApplicationModuleSections.SETTINGS}`,
		loadChildren: () => import('./featured/settings/settings.module').then((m) => m.SettingsModule),
		canActivate: [AuthGuard, PermissionGuard]
	},
	{
		path: `${ApplicationModuleSections.HISTORY_LOGS}`,
		loadChildren: () => import('./featured/history-logs/history-logs.module').then((m) => m.HistoryLogsModule),
		canActivate: [AuthGuard, FeatureGuard, PermissionGuard],
		data: { canActivateFeatureFlag: 'history' }
	},
	{
		path: `${ApplicationModuleSections.DTT_EXPORT}`,
		loadChildren: () => import('./featured/dtt-export/dtt-export.module').then((m) => m.DttExportModule),
		canActivate: [AuthGuard, PermissionGuard],
		data: { canActivateFeatureFlag: 'dtt' }
	},
	{
		path: `${ApplicationModuleSections.ACCOUNTING}`,
		loadChildren: () => import('./featured/accounting/accounting.module').then((m) => m.AccountingModule),
		canActivate: [AuthGuard, PermissionGuard],
		data: { canActivateFeatureFlag: 'accounting' }
	},
	{
		path: `${ApplicationModuleSections.PERFORMANCE}`,
		loadChildren: () => import('./featured/performance/performance.module').then((m) => m.PerformanceModule),
		canActivate: [AuthGuard, PermissionGuard],
		data: { canActivateFeatureFlag: 'performance' }
	},
	{
		path: `${ApplicationModuleSections.DTT_EXPORT_SOURCED}`,
		loadChildren: () => import('./featured/dtt-export/dtt-export.module').then((m) => m.DttExportModule),
		canActivate: [],
		data: { canActivateFeatureFlag: 'dtt' }
	},
	{
		path: `${ApplicationModuleSections.PERFORMANCE_SOURCED}`,
		loadChildren: () => import('./featured/performance/performance.module').then((m) => m.PerformanceModule),
		canActivate: [],
		data: { canActivateFeatureFlag: 'performance' }
	},
	{
		path: `${ApplicationModuleSections.ERROR}`,
		loadChildren: () => import('./shared/error/error.module').then((m) => m.ErrorModule),
		canActivate: [AuthGuard]
	},
	{
		path: `${REDIRECT_URL}`,
		component: RedirectComponent
	},
	{
		path: '',
		redirectTo: DEFAULT_INITIAL_ROUTE,
		pathMatch: 'full'
	},
	{
		path: '**',
		redirectTo: DEFAULT_INITIAL_ROUTE,
		pathMatch: 'full'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}
