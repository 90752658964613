import { HttpErrorResponse } from '@angular/common/http';
import * as api from '@dki/api-client';
import { createAction, props } from '@ngrx/store';
import { DateTime } from 'luxon';

import { STORE_SLICE_KEY } from './state';

export const getSafeDepositReports = createAction(`[ ${STORE_SLICE_KEY} / GET ] -> Safe Deposit`, props<{ from: DateTime; to: DateTime }>());
export const getSafeDepositReportsSuccess = createAction(`[ ${STORE_SLICE_KEY} / GET SUCCESS ] -> Safe Deposit`, props<{ data: Array<api.SafeRecord> }>());
export const getSafeDepositReportsFail = createAction(`[ ${STORE_SLICE_KEY} / GET FAIL ] -> Safe Deposit`, props<{ error: HttpErrorResponse }>());
