import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { ManifestAppVersionComponent } from './components/manifest-app-version/manifest-app-version.component';

const components = [ManifestAppVersionComponent];

@NgModule({
	declarations: [...components],
	imports: [CommonModule, HttpClientModule],
	providers: [],
	exports: [...components],
})
export class UiCommonModule {}
