/**
 * DKI-BE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0.dev0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpEvent, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { CustomHttpParameterCodec } from '../encoder';

import {
	ChannelOrdersWithAverages,
	DkiBeClickhouseAnalyticsBaseAggregation,
	DkiBeClickhouseAnalyticsChannelOrders,
	HourlySales,
	OrdersByTime,
	OrderStats,
	OutputFormatType,
	ProductOrders,
	SosDriveByTime,
	SosKioskByTime
} from '../model/models';

import { BASE_PATH } from '../variables';


@Injectable()
export class DataApiClient {

	protected basePath = 'http://localhost';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();
	public encoder: HttpParameterCodec;

	constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
		if (configuration) {
			this.configuration = configuration;
		}
		if (typeof this.configuration.basePath !== 'string') {
			if (typeof basePath !== 'string') {
				basePath = this.basePath;
			}
			this.configuration.basePath = basePath;
		}
		this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
	}


	private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
		if (typeof value === "object" && value instanceof Date === false) {
			httpParams = this.addToHttpParamsRecursive(httpParams, value);
		} else {
			httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
		}
		return httpParams;
	}

	private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
		if (value == null) {
			return httpParams;
		}

		if (typeof value === "object") {
			if (Array.isArray(value)) {
				(value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
			} else if (value instanceof Date) {
				if (key != null) {
					httpParams = httpParams.append(key,
						(value as Date).toISOString().substr(0, 10));
				} else {
					throw Error("key may not be null if value is Date");
				}
			} else {
				Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
					httpParams, value[k], key != null ? `${key}.${k}` : k));
			}
		} else if (key != null) {
			httpParams = httpParams.append(key, value);
		} else {
			throw Error("key may not be null if value is not object or array");
		}
		return httpParams;
	}

	/**
	 * Average Orders By Time
	 * Get average order values for the given time period, aggregated by hour.  The average is calculated for the last 10 weeks for the same weekday, excluding the weeks with the highest and the lowest total value for that weekday. For example, if the &#x60;from&#x60; is on Monday, then the last 10 mondays will be taken into account. The best and the worst Monday will be excluded.  If the restaurant\&#39;s closing hour is after midnight, all orders between midnight and closing hour are calculated towards the previous day.  Limitations:  - The average is always calculated for a single day of week. If the time   span between &#x60;from&#x60; and &#x60;to&#x60; exceeds one day, all average values   correspond to the first weekday of the interval (specified by the &#x60;from&#x60;   timestamp).
	 * @param restaurantId Restaurant UUID
	 * @param atCurrentTime
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public averageOrdersByTimeV1DataAverageOrdersByTimeGet(restaurantId: string, atCurrentTime?: boolean, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<OrdersByTime>>;
	public averageOrdersByTimeV1DataAverageOrdersByTimeGet(restaurantId: string, atCurrentTime?: boolean, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<OrdersByTime>>>;
	public averageOrdersByTimeV1DataAverageOrdersByTimeGet(restaurantId: string, atCurrentTime?: boolean, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<OrdersByTime>>>;
	public averageOrdersByTimeV1DataAverageOrdersByTimeGet(restaurantId: string, atCurrentTime?: boolean, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling averageOrdersByTimeV1DataAverageOrdersByTimeGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (atCurrentTime !== undefined && atCurrentTime !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>atCurrentTime, 'at_current_time');
		}
		if (restaurantId !== undefined && restaurantId !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>restaurantId, 'restaurant_id');
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}
		if (format !== undefined && format !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>format, 'format');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<OrdersByTime>>(`${this.configuration.basePath}/v1/data/average-orders-by-time`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Order Stats
	 * Get common order statistics for the given time period.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public orderStatsV1DataOrderStatsGet(restaurantId: string, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<OrderStats>;
	public orderStatsV1DataOrderStatsGet(restaurantId: string, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<OrderStats>>;
	public orderStatsV1DataOrderStatsGet(restaurantId: string, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<OrderStats>>;
	public orderStatsV1DataOrderStatsGet(restaurantId: string, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling orderStatsV1DataOrderStatsGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId !== undefined && restaurantId !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>restaurantId, 'restaurant_id');
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<OrderStats>(`${this.configuration.basePath}/v1/data/order-stats`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Orders By Channel
	 * Get order statistics per sales channel.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public ordersByChannelV1DataOrdersByChannelGet(restaurantId: string, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<DkiBeClickhouseAnalyticsChannelOrders>>;
	public ordersByChannelV1DataOrdersByChannelGet(restaurantId: string, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<DkiBeClickhouseAnalyticsChannelOrders>>>;
	public ordersByChannelV1DataOrdersByChannelGet(restaurantId: string, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<DkiBeClickhouseAnalyticsChannelOrders>>>;
	public ordersByChannelV1DataOrdersByChannelGet(restaurantId: string, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling ordersByChannelV1DataOrdersByChannelGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId !== undefined && restaurantId !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>restaurantId, 'restaurant_id');
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}
		if (format !== undefined && format !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>format, 'format');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<DkiBeClickhouseAnalyticsChannelOrders>>(`${this.configuration.basePath}/v1/data/orders-by-channel`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Orders By Channel With Averages
	 * Get order statistics per sales channel, including average values.  The averages are calculated for the last 10 weeks for the same weekday (see &#x60;/v1/data/average-orders-by-time&#x60; for details).
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public ordersByChannelWithAveragesV1DataOrdersByChannelWithAveragesGet(restaurantId: string, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<ChannelOrdersWithAverages>>;
	public ordersByChannelWithAveragesV1DataOrdersByChannelWithAveragesGet(restaurantId: string, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<ChannelOrdersWithAverages>>>;
	public ordersByChannelWithAveragesV1DataOrdersByChannelWithAveragesGet(restaurantId: string, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<ChannelOrdersWithAverages>>>;
	public ordersByChannelWithAveragesV1DataOrdersByChannelWithAveragesGet(restaurantId: string, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling ordersByChannelWithAveragesV1DataOrdersByChannelWithAveragesGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId !== undefined && restaurantId !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>restaurantId, 'restaurant_id');
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}
		if (format !== undefined && format !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>format, 'format');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<ChannelOrdersWithAverages>>(`${this.configuration.basePath}/v1/data/orders-by-channel-with-averages`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Orders By Product
	 * Get order statistics per product.  Mean values are calculated for the last 10 weeks for the same weekday. For each product, only the days with at least one sale of this product are taken into account.
	 * @param restaurantId Restaurant UUID
	 * @param expandMenus
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public ordersByProductV1DataOrdersByProductGet(restaurantId: Array<string>, expandMenus?: boolean, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<ProductOrders>>;
	public ordersByProductV1DataOrdersByProductGet(restaurantId: Array<string>, expandMenus?: boolean, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<ProductOrders>>>;
	public ordersByProductV1DataOrdersByProductGet(restaurantId: Array<string>, expandMenus?: boolean, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<ProductOrders>>>;
	public ordersByProductV1DataOrdersByProductGet(restaurantId: Array<string>, expandMenus?: boolean, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling ordersByProductV1DataOrdersByProductGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (expandMenus !== undefined && expandMenus !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>expandMenus, 'expand_menus');
		}
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}
		if (format !== undefined && format !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>format, 'format');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<ProductOrders>>(`${this.configuration.basePath}/v1/data/orders-by-product`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Orders By Time And Channel
	 * Get order statistics per sales channel per 15 minute interval.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public ordersByTimeAndChannelV1DataOrdersByTimeAndChannelGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HourlySales>;
	public ordersByTimeAndChannelV1DataOrdersByTimeAndChannelGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<HourlySales>>;
	public ordersByTimeAndChannelV1DataOrdersByTimeAndChannelGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<HourlySales>>;
	public ordersByTimeAndChannelV1DataOrdersByTimeAndChannelGet(restaurantId: Array<string>, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling ordersByTimeAndChannelV1DataOrdersByTimeAndChannelGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId) {
			restaurantId.forEach((element) => {
				queryParameters = this.addToHttpParams(queryParameters,
					<any>element, 'restaurant_id');
			})
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<HourlySales>(`${this.configuration.basePath}/v1/data/orders-by-time-and-channel`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Orders By Time
	 * Get total order values for the given time period, aggregated by hour.
	 * @param restaurantId Restaurant UUID
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public ordersByTimeV1DataOrdersByTimeGet(restaurantId: string, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<OrdersByTime>>;
	public ordersByTimeV1DataOrdersByTimeGet(restaurantId: string, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<OrdersByTime>>>;
	public ordersByTimeV1DataOrdersByTimeGet(restaurantId: string, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<OrdersByTime>>>;
	public ordersByTimeV1DataOrdersByTimeGet(restaurantId: string, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling ordersByTimeV1DataOrdersByTimeGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (restaurantId !== undefined && restaurantId !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>restaurantId, 'restaurant_id');
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}
		if (format !== undefined && format !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>format, 'format');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<OrdersByTime>>(`${this.configuration.basePath}/v1/data/orders-by-time`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Sos Drive By Time
	 * Get aggregated SOS statistics.
	 * @param restaurantId Restaurant UUID
	 * @param aggregation
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public sosDriveByTimeV1DataSosByTimeDriveGet(restaurantId: string, aggregation?: DkiBeClickhouseAnalyticsBaseAggregation, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<SosDriveByTime>>;
	public sosDriveByTimeV1DataSosByTimeDriveGet(restaurantId: string, aggregation?: DkiBeClickhouseAnalyticsBaseAggregation, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<SosDriveByTime>>>;
	public sosDriveByTimeV1DataSosByTimeDriveGet(restaurantId: string, aggregation?: DkiBeClickhouseAnalyticsBaseAggregation, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<SosDriveByTime>>>;
	public sosDriveByTimeV1DataSosByTimeDriveGet(restaurantId: string, aggregation?: DkiBeClickhouseAnalyticsBaseAggregation, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling sosDriveByTimeV1DataSosByTimeDriveGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (aggregation !== undefined && aggregation !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>aggregation, 'aggregation');
		}
		if (restaurantId !== undefined && restaurantId !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>restaurantId, 'restaurant_id');
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}
		if (format !== undefined && format !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>format, 'format');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<SosDriveByTime>>(`${this.configuration.basePath}/v1/data/sos-by-time/drive`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

	/**
	 * Sos Kiosk By Time
	 * Get aggregated SOS statistics.
	 * @param restaurantId Restaurant UUID
	 * @param aggregation
	 * @param from Date and time in the ISO 8601 format  If omitted and no businessday_from given, today\&#39;s opening time for the given restaurant is used.
	 * @param to Date and time in the ISO 8601 format  If omitted and no businessday_to given, today\&#39;s closing time for the given restaurant is used.
	 * @param businessdayFrom Businessday in the ISO 8601 format
	 * @param businessdayTo Businessday in the ISO 8601 format
	 * @param format
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public sosKioskByTimeV1DataSosByTimeKioskGet(restaurantId: string, aggregation?: DkiBeClickhouseAnalyticsBaseAggregation, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'body', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<Array<SosKioskByTime>>;
	public sosKioskByTimeV1DataSosByTimeKioskGet(restaurantId: string, aggregation?: DkiBeClickhouseAnalyticsBaseAggregation, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'response', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpResponse<Array<SosKioskByTime>>>;
	public sosKioskByTimeV1DataSosByTimeKioskGet(restaurantId: string, aggregation?: DkiBeClickhouseAnalyticsBaseAggregation, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe?: 'events', reportProgress?: boolean, options?: { httpHeaderAccept?: 'application/json' }): Observable<HttpEvent<Array<SosKioskByTime>>>;
	public sosKioskByTimeV1DataSosByTimeKioskGet(restaurantId: string, aggregation?: DkiBeClickhouseAnalyticsBaseAggregation, from?: string, to?: string, businessdayFrom?: string, businessdayTo?: string, format?: OutputFormatType, observe: any = 'body', reportProgress: boolean = false, options?: { httpHeaderAccept?: 'application/json' }): Observable<any> {
		if (restaurantId === null || restaurantId === undefined) {
			throw new Error('Required parameter restaurantId was null or undefined when calling sosKioskByTimeV1DataSosByTimeKioskGet.');
		}

		let queryParameters = new HttpParams({encoder: this.encoder});
		if (aggregation !== undefined && aggregation !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>aggregation, 'aggregation');
		}
		if (restaurantId !== undefined && restaurantId !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>restaurantId, 'restaurant_id');
		}
		if (from !== undefined && from !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>from, 'from');
		}
		if (to !== undefined && to !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>to, 'to');
		}
		if (businessdayFrom !== undefined && businessdayFrom !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayFrom, 'businessday_from');
		}
		if (businessdayTo !== undefined && businessdayTo !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>businessdayTo, 'businessday_to');
		}
		if (format !== undefined && format !== null) {
			queryParameters = this.addToHttpParams(queryParameters,
				<any>format, 'format');
		}

		let headers = this.defaultHeaders;

		let credential: string | undefined;
		// authentication (APIKeyCookie) required
		credential = this.configuration.lookupCredential('APIKeyCookie');
		if (credential) {
		}

		// authentication (HTTPBasic) required
		credential = this.configuration.lookupCredential('HTTPBasic');
		if (credential) {
			headers = headers.set('Authorization', 'Basic ' + credential);
		}

		// authentication (OAuth2PasswordBearer) required
		credential = this.configuration.lookupCredential('OAuth2PasswordBearer');
		if (credential) {
			headers = headers.set('Authorization', 'Bearer ' + credential);
		}

		let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (httpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [
				'application/json'
			];
			httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (httpHeaderAcceptSelected !== undefined) {
			headers = headers.set('Accept', httpHeaderAcceptSelected);
		}


		let responseType: 'text' | 'json' = 'json';
		if (httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
			responseType = 'text';
		}

		return this.httpClient.get<Array<SosKioskByTime>>(`${this.configuration.basePath}/v1/data/sos-by-time/kiosk`,
			{
				params: queryParameters,
				responseType: <any>responseType,
				withCredentials: this.configuration.withCredentials,
				headers: headers,
				observe: observe,
				reportProgress: reportProgress
			}
		);
	}

}
