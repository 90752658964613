import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as api from '@dki/api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { formatDateISO } from '../../utils/functions';
import { MyRestaurantsSelectors } from '../my-restaurants';
import { RootStoreState } from '../state';
import * as AccountingActions from './actions';

@Injectable()
export class AccountingEffects {
	getOrdersByTime = createEffect(() =>
		this._actions.pipe(
			ofType(AccountingActions.getAccountingReport),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId))),
			filter(([a, defaultRestaurantId]) => {
				return !!defaultRestaurantId;
			}),
			mergeMap(([a, defaultRestaurantId]) => {
				return this._dataApiClient.getReportV1AccountingReportReportGet(formatDateISO(a.businessDate.toISO()), defaultRestaurantId).pipe(
					map((accountingReport: api.AccountingReportResponse) => {
						return AccountingActions.getAccountingReportSuccess({
							accountingReport,
						});
					}),
					catchError((error: HttpErrorResponse) => {
						return of(AccountingActions.getAccountingReportFail({ error }));
					}),
				);
			}),
		),
	);

	getPendingReports = createEffect(() =>
		this._actions.pipe(
			ofType(AccountingActions.getPendingReports),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId))),
			filter(([a, defaultRestaurantId]) => {
				return !!defaultRestaurantId;
			}),
			mergeMap(([_a, _defaultRestaurantId]) => {
				return this._dataApiClient.getPendingReportsV1AccountingReportPendingReportsGet().pipe(
					map(
						(pendingReports: Array<api.AccountingReport>) => {
							return AccountingActions.getPendingReportsSuccess({ pendingReports });
						},
						catchError((error: HttpErrorResponse) => of(AccountingActions.getPendingReportsFail({ error }))),
					),
				);
			}),
		),
	);

	confirmReport = createEffect(() =>
		this._actions.pipe(
			ofType(AccountingActions.confirmReport),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId))),
			filter(([a, defaultRestaurantId]) => {
				return !!defaultRestaurantId;
			}),
			mergeMap(([a, defaultRestaurantId]) => {
				return this._dataApiClient.confirmReportV1AccountingReportConfirmGet(formatDateISO(a.businessDate.toISO()), defaultRestaurantId).pipe(
					map(
						() => {
							this.router.navigate(['/accounting']);
							return AccountingActions.confirmReportSuccess();
						},
						catchError((error: HttpErrorResponse) => of(AccountingActions.confirmReportFail({ error }))),
					),
				);
			}),
		),
	);

	updateReport = createEffect(() =>
		this._actions.pipe(
			ofType(AccountingActions.updateReport),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId))),
			filter(([a, defaultRestaurantId]) => {
				return !!defaultRestaurantId;
			}),
			mergeMap(([a, defaultRestaurantId]) => {
				return this._dataApiClient
					.setValuesByPaymentMethodV1AccountingReportSetValuesByPaymentMethodPost(formatDateISO(a.businessDate.toISO()), defaultRestaurantId, a.values)
					.pipe(
						map(() => {
							return AccountingActions.updateReportSuccess({businessDate: a.businessDate});
						}),
						catchError((error: HttpErrorResponse) => of(AccountingActions.updateReportFail({ error }))),
					);
			}),
		),
	);

	fetchUpdatedReport = createEffect(() =>
		this._actions.pipe(
			ofType(AccountingActions.updateReportSuccess),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId))),
			filter(([a, defaultRestaurantId]) => {
				return !!defaultRestaurantId;
			}),
			mergeMap(([a, defaultRestaurantId]) => {
				return this._dataApiClient.getReportV1AccountingReportReportGet(formatDateISO(a.businessDate.toISO()), defaultRestaurantId).pipe(
					map((accountingReport) => {
						return AccountingActions.getAccountingReportSuccess({ accountingReport });
					}),
					catchError((error: HttpErrorResponse) => of(AccountingActions.getAccountingReportFail({ error }))),
				);
			}),
		),
	);

	constructor(private _actions: Actions<Action>, private _dataApiClient: api.AccountingReportApiClient, private _store: Store<RootStoreState>, private router: Router) {}
}
