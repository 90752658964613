import * as api from '@dki/api-client';
import { DateTime } from 'luxon';

import { Range } from '../../entity';

export const STORE_SLICE_KEY = 'SessionHistory';

export interface SessionHistoryState {
	data: Array<api.PosRecord> | any;
	isLoading?: boolean;
	from?: DateTime;
	to?: DateTime;
}
export const SESSION_HISTORY_INITIAL_STATE: SessionHistoryState = {
	data: [],
	from: DateTime.now().startOf(Range.Week),
	to: DateTime.now().endOf(Range.Week),
};
