import { Action, createReducer, on } from '@ngrx/store';

import * as Actions from './actions';
import { ACCOUNTING_INITIAL_STATE, AccountingState } from './state';

export const AccountingReportReducer = createReducer<AccountingState, Action>(
	ACCOUNTING_INITIAL_STATE,
	on(Actions.getAccountingReport, (state, _action) => ({ ...state, isLoading: true })),
	on(Actions.getAccountingReportFail, (state, _action) => ({
		...state,
		isLoading: false,
		error: _action.error,
	})),
	on(Actions.getAccountingReportSuccess, (state, action) => ({
		...state,
		accountingReport: action.accountingReport,
		isLoading: false,
		error: false,
	})),

	on(Actions.getPendingReports, (state, _action) => ({ ...state, isLoading: true })),
	on(Actions.getPendingReportsFail, (state, _action) => ({
		...state,
		isLoading: false,
		error: _action.error,
	})),
	on(Actions.getPendingReportsSuccess, (state, action) => ({
		...state,
		pendingReports: action.pendingReports,
		isLoading: false,
	})),

	on(Actions.confirmReport, (state, _action) => ({ ...state, isLoading: true})),
	on(Actions.confirmReportFail, (state, _action) => ({ ...state, isLoading: false, error: _action.error })),
	on(Actions.confirmReportSuccess, (state, _action) => ({ ...state, isLoading: false, error: false })),

	on(Actions.updateReport, (state, _action) => ({ ...state, isLoading: true })),
	on(Actions.updateReportFail, (state, _action) => ({ ...state, isLoading: false, error: _action.error })),
	on(Actions.updateReportSuccess, (state, _action) => ({ ...state, error: false })),
);
