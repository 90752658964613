import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { isAuthenticated } from '../../store/auth/selectors';
import { State } from '../../store/router/state';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(private _store: Store<State>, private _router: Router) {}

	canActivate(): Observable<boolean> {
		return this._store.pipe(select(isAuthenticated)).pipe(
			map((authenticated) => {
				if (!authenticated) {
					this._router.navigateByUrl('/account/login');
				}

				return authenticated;
			})
		);
	}
}
