import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '../../../environments/environment';
import { AccountingStoreModule } from './accounting/accounting-store.module';
import { AuthStoreModule } from './auth/auth-store.module';
import { HistoricStatisticsStoreModule } from './historic-statistics/historic-statistics-store.module';
import { MyRestaurantsStoreModule } from './my-restaurants/my-restaurants-store.module';
import { PerformanceStoreModule } from './performance/performance.store.module';
import { ReportsStoreModule } from './reports/reports-store.module';
import { NgrxRouterStoreModule } from './router/router-store.module';
import { SafeDepositStoreModule } from './safe-deposit/safe-deposit-store.module';
import { SessionsHistoryStoreModule } from './sessions-history/sessions-history.module';
import { SynthesisReportStoreModule } from './synthesis-report/synthesis-report-store.module';
import { TodaysStatisticsStoreModule } from './todays-statistics/todays-statistics-store.module';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		StoreModule.forRoot({}),
		NgrxRouterStoreModule,
		EffectsModule.forRoot(),
		AuthStoreModule,
		MyRestaurantsStoreModule,
		TodaysStatisticsStoreModule,
		HistoricStatisticsStoreModule,
		ReportsStoreModule,
		SessionsHistoryStoreModule,
		SafeDepositStoreModule,
		SynthesisReportStoreModule,
		AccountingStoreModule,
		PerformanceStoreModule,
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: environment.production,
		}),
	],
})
export class RootStoreModule {}
