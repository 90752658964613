export const DEFAULT_TIMEZONE = {
	continent: 'Europe',
	city: 'Paris'
};

export const TODAYS_STATISTICS_GRID_TILES = {
	channelPerformance: {
		colspan: 1,
		title: 'Channel performance',
		translationKey: 'channel-performance',
		table: {
			cssClass: '',
			rowClickAction: 'viewDetail',
			dataSourceName: 'ordersByChannelWithAverages'
		}
	},
	productSalesStatus: {
		colspan: 2,
		title: 'Product sales status',
		translationKey: 'product-sales-status',
		table: {
			cssClass: 'dasking-product-sales-status-table',
			rowClickAction: 'viewDetail',
			dataSourceName: 'todaysStatisticsGridTiles'
		}
	},
	customerWaitingTime: {
		colspan: 1,
		title: 'Customer waiting time',
		translationKey: 'customer-waiting-time',
		table: {
			cssClass: 'dasking-customers-waiting-time-table'
		}
	}
};

export const QUERY_PARAM_RESTO_UUID = 'resto_uuid';
export const QUERY_PARAM_RESTO_UUID_HYPHEN = 'resto-uuid';
