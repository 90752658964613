import { Component } from '@angular/core';
import { Meta } from '@angular/platform-browser';

import { CustomIconRegistryService } from './core/services/custom-icon-registry.service';

@Component({
	selector: 'dk-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	constructor(crs: CustomIconRegistryService, meta: Meta) {
		crs.registerInitialCustomIcons();

		// TODO: set from the retailer configuration (+ mask-icon in index.html)
		meta.addTag({ name: 'theme-color', content: '#bb1e32' });
		meta.addTag({ name: 'msapplication-TileColor', content: '#ffffff' });
	}
}
