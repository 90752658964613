import { Action, createReducer, on } from '@ngrx/store';

import { AuthStoreActions } from '../auth';
import * as Actions from './actions';
import { TODAYS_STATISTICS_INITIAL_STATE, TodaysStatisticsState } from './state';

export const todaysStatisticsReducer = createReducer<TodaysStatisticsState, Action>(
	TODAYS_STATISTICS_INITIAL_STATE,
	on(
		Actions.getOrdersByTime,
		Actions.getOrdersByProduct,
		Actions.getOrdersByChannel,
		Actions.getOrdersByChannelWithAverages,
		Actions.getAverageOrdersAtCurrentTime,
		Actions.getSos,
		Actions.getKioskSos,
		Actions.getLast15minutesOrdersTotalCount,
		(state) => ({ ...state, isLoading: true }),
	),
	on(Actions.getOrdersByTimeSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			ordersByTime: action.ordersByTime,
		},
		status: {isLoading: false },
	})),
	on(Actions.getAverageOrdersAtCurrentTimeSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			averageOrdersAtCurrentTime: action.averageOrdersAtCurrentTime,
		},
		status: {isLoading: false },
	})),
	on(Actions.getLast15minutesOrdersTotalCountSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			last15minutesOrdersTotalCount: action.last15minutesOrdersTotalCount,
		},
		status: {isLoading: false },
	})),
	on(Actions.getOrdersByProductSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			ordersByProduct: action.ordersByProduct,
		},
		status: {isLoading: false },
	})),
	on(Actions.getOrdersByChannelSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			ordersByChannel: action.ordersByChannel,
		},
		status: {isLoading: false },
	})),
	on(Actions.getOrdersByChannelWithAveragesSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			ordersByChannelWithAverages: action.ordersByChannelWithAverages,
		},
		status: {isLoading: false },
	})),
	on(Actions.getSosSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			sos: action.sos,
		},
		status: {isLoading: false },
	})),
	on(Actions.getKioskSosSuccess, (state, action) => ({
		...state,
		data: {
			...state.data,
			kioskSos: action.sos,
		},
		status: {isLoading: false },
	})),
	on(AuthStoreActions.logout, () => (TODAYS_STATISTICS_INITIAL_STATE)),
);
