import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root',
})
export class CustomIconRegistryService {
	constructor(private _matIconRegistry: MatIconRegistry, private _domSanitizer: DomSanitizer) {}

	registerInitialCustomIcons(): void {
		const iconsToBeAddedToCustomRegistry = [
			'arrow',
			'pie',
			'chart',
			'settings',
			'logout',
			'pen',
			'yellow_dot',
			'orange_dot',
			'red_dot',
			'reports',
			'history',
			'safe',
			'garage',
			'accounting',
			'performance',
		];

		iconsToBeAddedToCustomRegistry.forEach((iconName) => {
			const pathToIcon = `../assets/icons/${iconName}.svg`;
			this.addCustomIconToRegistry(iconName, pathToIcon);
		});
	}

	private addCustomIconToRegistry(iconName: string, pathToIcon: string): void {
		this._matIconRegistry.addSvgIcon(iconName, this._domSanitizer.bypassSecurityTrustResourceUrl(pathToIcon));
	}
}
