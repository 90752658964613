export const HTTP = 'http';
export const HTTPS = 'https';
export const MAX_PROTOCOL_NUMBER = 65535;

export const DELAY_50 = 50;
export const DELAY_100 = 100;
export const DELAY_200 = 200;
export const DELAY_1000 = 1000;
export const DELAY_2000 = 2000;
export const DELAY_5000 = 5000;

export const PASSWORD_ERRORS = {
	REQUIRED: 'required',
	NOT_SAME: 'notSame',
	DUPLICATE: 'duplicate',
	NOT_STRONG: 'notStrong'
};

export const PASSWORD_TYPES = {
	NEW: 'new',
	CONFIRM: 'confirm',
	CURRENT: 'current'
} as const;

export const FORM_STATUS_CHANGE = {
	INVALID: 'INVALID',
	VALID: 'VALID',
	PENDING: 'PENDING',
	DISABLED: 'DISABLED'
};

export const MIN_PASSWORD_SCORE = 3;

export const HTTP_RETURN_CODES = {
	UNPROCESSABLE_ENTITY: 422
};

// Animation closing timeout in ms
export const CLOSING_TIMEOUT = 250;
