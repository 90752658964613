export const UNKNOWN = 'UNKNOWN';

// Legacy name. New app will be most likely called "POS"
export const BK_CASH = 'BK_CASH';
export const BK_KIOSK = 'BK_KIOSK';
export const PRINT_SERVER = 'PRINT_SERVER';
export const RAP_BE = 'RAP_BE';
export const ORBp = 'ORBp';
export const BK_BO_LOCAL_MANAGER = 'BK_BO_LOCAL_MANAGER';
export const SMART_COD = 'SMART_COD';

/**
 * Applications capable of sending Ring Messages:
 */
export type APPLICATION_NAME =
	typeof UNKNOWN
	| typeof BK_CASH
	| typeof BK_KIOSK
	| typeof PRINT_SERVER
	| typeof RAP_BE
	| typeof ORBp
	| typeof BK_BO_LOCAL_MANAGER
	| typeof SMART_COD;

export const APPLICATION_NAME_PROPERTY = 'applicationName';
