import { HttpErrorResponse } from '@angular/common/http';
import * as api from '@dki/api-client';
import { createAction, props } from '@ngrx/store';

import { STORE_SLICE_KEY } from './state';

export const getList = createAction(`[${STORE_SLICE_KEY}] -> Get List`);
export const getListSuccess = createAction(`[${STORE_SLICE_KEY}] -> Get List Success`, props<{ listOfMyRestaurants: api.Restaurant[] }>());
export const getListFail = createAction(`[${STORE_SLICE_KEY}] -> Get List Failure`, props<{ error: HttpErrorResponse | null }>());

export const updateMyDefaultRestaurant = createAction(`[${STORE_SLICE_KEY}] -> Update Default Restaurant`, props<{ restaurantSettings: api.UpdateRestaurantSettings }>());
export const updateMyDefaultRestaurantSuccess = createAction(`[${STORE_SLICE_KEY}] -> Update Default Restaurant Success`);
export const updateMyDefaultRestaurantFail = createAction(`[${STORE_SLICE_KEY}] -> Update Default Restaurant Failure`, props<{ error: HttpErrorResponse | null }>());

export const getLongTermOrders = createAction(`[${STORE_SLICE_KEY}] -> Get Long Term Orders`);
export const getLongTermOrdersSuccess = createAction(`[${STORE_SLICE_KEY}] -> Get Long Term Orders Success`, props<{ longTermOrders: api.OrdersByTime[] }>());
export const getLongTermOrdersFail = createAction(`[${STORE_SLICE_KEY}] -> Get Long Term Orders Failure`, props<{ error: HttpErrorResponse | null }>());

export const setRestaurant = createAction(`[${STORE_SLICE_KEY}] -> Set Restaurant`, props<{ mySelectedRestaurant: api.Restaurant }>());
export const setMultiRestaurants = createAction(`[${STORE_SLICE_KEY}] -> Set Multi Restaurant`, props<{ mySelectedRestaurants: api.Restaurant[] }>());
export const enableMultiSelection = createAction(`[${STORE_SLICE_KEY}] -> Enable Multi Restaurant Selection`, props<{ enabler: boolean }>());

export const getPersistedRestaurants = createAction(`[${STORE_SLICE_KEY}] -> Get Persisted Restaurant Success`);
