import * as api from '@dki/api-client';
import { DateTime } from 'luxon';

import { Range } from '../../entity';

export const STORE_SLICE_KEY = 'SafeDeposit';

export interface SafeDepositState {
	data: Array<api.SafeRecord> | any;
	isLoading?: boolean;
	from?: DateTime;
	to?: DateTime;
}
export const SAFE_DEPOSIT_INITIAL_STATE: SafeDepositState = {
	data: [],
	from: DateTime.now().startOf(Range.Week),
	to: DateTime.now().endOf(Range.Week),
};
