import { Router } from '@angular/router';
import * as gapApi from '@gap/api-client';
import { DateTime } from 'luxon';

import { QUERY_PARAM_RESTO_UUID, QUERY_PARAM_RESTO_UUID_HYPHEN } from '../../shared/entity';
import { DEFAULT_LOCALE_LANGUAGE_KEY, LanguageISOCode } from '../entity';

const reloadCurrentRoute = (router: Router) => {
	const currentUrl = router.url;
	router.navigateByUrl('/').then(() => {
		router.navigate([currentUrl]);
	});
	location.reload();
};

const getPreferredLanguage = (): LanguageISOCode => {
	const lang = localStorage.getItem('lang') as LanguageISOCode;
	return lang ? lang : DEFAULT_LOCALE_LANGUAGE_KEY;
};

const setPreferredLanguage = (lang: LanguageISOCode) => {
	localStorage.setItem('lang', lang);
};

// used in dki to download csv
// downloadBloB( json , filename, 'text/csv;charset=utf-8' ) )
const downloadBlob = (content, filename, contentType) => {
	const blob = new Blob([content], { type: contentType });
	const url = URL.createObjectURL(blob);
	const pom = document.createElement('a');
	pom.href = url;
	pom.setAttribute('download', filename);
	pom.click();
};

const getLocale = () => {
	if (getPreferredLanguage() === LanguageISOCode.FR) {
		return 'fr-fr';
	}
	return 'en-gb';
};

const formatDateISO = (date: string) => {
	return DateTime.fromISO(date).toFormat('yyyy-MM-dd');
};

const getRestoUuidFromMap = (map: any): string => {
	return map[QUERY_PARAM_RESTO_UUID_HYPHEN] ? map[QUERY_PARAM_RESTO_UUID_HYPHEN] : map[QUERY_PARAM_RESTO_UUID];
};

const hasReportingPermission = (permissions: Array<gapApi.PermissionInfoReadWrite | gapApi.PermissionInfoLegacyAccess | gapApi.PermissionInfoApplyDefine>) => {
	const reportingPermission = permissions.find((e) => e.name === gapApi.PermissionInfoReadWrite.NameEnum.Reporting);
	return !!reportingPermission && reportingPermission.level !== null;
};

const hasAccessPermission = (permissions: Array<gapApi.PermissionInfoReadWrite | gapApi.PermissionInfoLegacyAccess | gapApi.PermissionInfoApplyDefine>): boolean => {
	const restaurantManagementPermission = permissions.find((e) => e.name === gapApi.PermissionInfoReadWrite.NameEnum.RestaurantManagement);
	return !!restaurantManagementPermission && restaurantManagementPermission.level !== null;
};

export { downloadBlob, formatDateISO, getLocale, getPreferredLanguage, getRestoUuidFromMap, hasAccessPermission, hasReportingPermission, reloadCurrentRoute, setPreferredLanguage };
