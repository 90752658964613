<h1 mat-dialog-title>{{ title }}</h1>

<mat-dialog-content>
	<img src="assets/images/error_message_background.svg" alt="Error message background" title="Error message background" />

	<p>{{ message }}</p>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center stretch">
	<button mat-raised-button color="primary" mat-dialog-close>OK</button>
</mat-dialog-actions>
