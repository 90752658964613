import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as api from '@dki/api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { Range } from '../../entity';
import { formatDateISO } from '../../utils/functions';
import { MyRestaurantsSelectors } from '../my-restaurants';
import { RootStoreState } from '../state';
import * as SafeDepositActions from './actions';

@Injectable()
export class SafeDepositEffects {
	getSafeDeposit = createEffect(() =>
		this._actions.pipe(
			ofType(SafeDepositActions.getSafeDepositReports),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId))),
			filter(([, defaultRestaurantId]) => !!defaultRestaurantId),
			mergeMap(([a, defaultRestaurantId]) => {
				return this._safeDepositApiClient
					.getSafeSessionsReportV1ReportsSafeSessionsGet(
						[defaultRestaurantId],
						null,
						null,
						formatDateISO(a.from.startOf(Range.Day).startOf(Range.Second).toISO()),
						formatDateISO(a.to.endOf(Range.Day).startOf(Range.Second).toISO()),
					)
					.pipe(
						map(
							(data) => SafeDepositActions.getSafeDepositReportsSuccess({ data }),
							catchError((error: HttpErrorResponse) => of(SafeDepositActions.getSafeDepositReportsFail({ error }))),
						),
					);
			}),
		),
	);

	constructor(private _actions: Actions, private _safeDepositApiClient: api.ReportsApiClient, private _store: Store<RootStoreState>) {}
}
