import { Action, createReducer, on } from '@ngrx/store';

import * as AuthStoreActions from './actions';
import { AuthState } from './state';

export const authReducer = createReducer<AuthState, Action>(
	{ isLoading: false },
	on(AuthStoreActions.login, (state) => ({ ...state, isLoading: true })),
	on(AuthStoreActions.loginSuccess, AuthStoreActions.getCurrentUserProfile, (state, action) => ({
		...state,
		accessToken: action.accessToken.access_token
	})),
	on(AuthStoreActions.refreshSessionFromCookie, (state, action) => ({
		...state,
		accessToken: action.accessToken
	})),
	on(AuthStoreActions.getCurrentUserProfileSuccess, AuthStoreActions.refreshSessionFromCookieSuccess, (state, action) => {
		return {
			...state,
			currentUser: {
				...state.currentUser,
				id: action.userInfo.id,
				name: {
					...state.currentUser?.name,
					full: `${action.userInfo.first_name || ''} ${action.userInfo.last_name || ''}`
				},
				contactDetails: {
					...state.currentUser?.contactDetails,
					email: {
						...state.currentUser?.contactDetails?.email,
						administrative: action.userInfo.email
					}
				},
				has_all_restaurants: action.userInfo.has_all_restaurants,
				permissions: action.userInfo.permissions,
				is_admin: action.userInfo.is_admin
			},
			isLoading: false
		};
	}),
	on(AuthStoreActions.logoutSuccess, () => ({ isLoading: false })),
	on(AuthStoreActions.loginFail, () => ({ isLoading: false })),
	// TO DO: UTILISE ERROR HANDLING
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	on(AuthStoreActions.loginFail, (state, { error }) => ({ ...state }))
);
