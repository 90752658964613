import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges } from '@angular/core';

import { ManifestReaderService } from '../../services/manifest-reader.service';

export enum ManifestAppVersionFontColor {
	WHITE = 'white',
	BLACK = 'black',
}

export enum ManifestAppVersionFontSize {
	SMALL = '8px',
	MID = '10px',
	LARGE = '12px'
}

@Component({
	selector: 'ui-manifest-app-version',
	templateUrl: './manifest-app-version.component.html',
	styleUrls: ['./manifest-app-version.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManifestAppVersionComponent implements OnChanges {
	defaultBorderTopColor = 'transparent';
	defaultColor = ManifestAppVersionFontColor.WHITE;
	defaultFontSize = ManifestAppVersionFontSize.SMALL;
	defaultManifestLocation = '/assets/configs/manifest.json';

	@Input() manifestLocation: string = this.defaultManifestLocation;
	@Input() showLabel = false;
	@Input() showTopBorder = false;
	@Input() fontColor: ManifestAppVersionFontColor = this.defaultColor;
	@Input() fontSize: ManifestAppVersionFontSize = this.defaultFontSize;

	@HostBinding('style.border-top-color') hostInlineCssBorderTopColor = this.defaultBorderTopColor;
	@HostBinding('style.color') hostInlineCssFontColor = this.defaultColor;
	@HostBinding('style.font-size') hostInlineCssFontSize = this.defaultFontSize;

	$currentAppVesrion = this._manifestReaderService.getAppVersion(this.manifestLocation);

	constructor(private _manifestReaderService: ManifestReaderService) {}

	public ngOnChanges(): void {
		this._applyInlineStyles();
	}

	private _applyInlineStyles(): void {
		this.hostInlineCssBorderTopColor = this.showTopBorder ? this.fontColor : this.defaultBorderTopColor;
		this.hostInlineCssFontColor = this.fontColor;
		this.hostInlineCssFontSize = this.fontSize;
	}
}
