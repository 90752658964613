import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationManifest } from '@merim/utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ManifestReaderService {
	private _defaultManifestLocation = '/assets/configs/manifest.json';

	constructor(private _http: HttpClient) {}

	public getAppVersion(localManifestLocation?: string): Observable<string> {
		const url = localManifestLocation ? localManifestLocation : this._defaultManifestLocation;
        return this._http.get<ApplicationManifest>(url).pipe(map((manifest: ApplicationManifest) => {
			return !!manifest && manifest.version ? manifest.version : 'N/A';
		}));
    }

	public getManifestData(localManifestLocation?: string): Observable<ApplicationManifest> {
		const url = localManifestLocation ? localManifestLocation : this._defaultManifestLocation;
        return this._http.get<ApplicationManifest>(url);
    }
}
