import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { FeatureMapService } from './core/services/feature-map/feature-map.service';
import { getLocale } from './core/utils/functions';
import { PageAsideNavigationModule } from './shared/page-aside-navigation/page-aside-navigation.module';
import { TranslocoRootModule } from './transloco/transloco-root.module';

@NgModule({
	declarations: [AppComponent],
	imports: [BrowserModule, BrowserAnimationsModule, HttpClientModule, CoreModule, AppRoutingModule, TranslocoRootModule, PageAsideNavigationModule],
	bootstrap: [AppComponent],
	providers: [
		{
			provide: APP_INITIALIZER,
			multi: true,
			deps: [FeatureMapService],
			useFactory: (fm: FeatureMapService) => {
				return () => {
					return fm.loadFeatureMap();
				};
			},
		},
		{ provide: MAT_DATE_LOCALE, useValue: getLocale() },
		{ provide: LOCALE_ID, useValue: getLocale() },
	],
})
export class AppModule {}
