/* eslint-disable @typescript-eslint/no-unused-vars */
import { Action, createReducer, on } from '@ngrx/store';

import * as Actions from './actions';
import { SAFE_DEPOSIT_INITIAL_STATE, SafeDepositState } from './state';

export const safeDepositReducer = createReducer<SafeDepositState, Action>(
	SAFE_DEPOSIT_INITIAL_STATE,
	on(Actions.getSafeDepositReports, (state, _action) => ({ ...state, isLoading: true, error: undefined, from: _action.from, to: _action.to })),

	on(Actions.getSafeDepositReportsFail, (state, _action) => ({
		...state,
		isLoading: false,
		error: true,
	})),

	on(Actions.getSafeDepositReportsSuccess, (state, action) => ({
		...state,
		data: action.data,
		isLoading: false,
		error: false,
	})),
);
