import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AllergensApiClient } from './api/allergens-api-client.service';
import { AuthApiClient } from './api/auth-api-client.service';
import { ConfigurationApiClient } from './api/configuration-api-client.service';
import { GAPApiClient } from './api/gap-api-client.service';
import { Global3ApiClient } from './api/global3-api-client.service';
import { MediasApiClient } from './api/medias-api-client.service';
import { MonitoringApiClient } from './api/monitoring-api-client.service';
import { MyApiClient } from './api/my-api-client.service';
import { OwnersApiClient } from './api/owners-api-client.service';
import { PaymentMethodsApiClient } from './api/payment-methods-api-client.service';
import { PricesApiClient } from './api/prices-api-client.service';
import { RestaurantsApiClient } from './api/restaurants-api-client.service';
import { TagsApiClient } from './api/tags-api-client.service';
import { UsersApiClient } from './api/users-api-client.service';
import { VatTariffsApiClient } from './api/vat-tariffs-api-client.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AllergensApiClient,
    AuthApiClient,
    ConfigurationApiClient,
    GAPApiClient,
    Global3ApiClient,
    MediasApiClient,
    MonitoringApiClient,
    MyApiClient,
    OwnersApiClient,
    PaymentMethodsApiClient,
    PricesApiClient,
    RestaurantsApiClient,
    TagsApiClient,
    UsersApiClient,
    VatTariffsApiClient ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
