import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as api from '@dki/api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { Range } from '../../entity';
import { FeatureMapService } from '../../services/feature-map/feature-map.service';
import { formatDateISO } from '../../utils/functions';
import { MyRestaurantsSelectors } from '../my-restaurants';
import { RootStoreState } from '../state';
import * as SynthesisReportAction from './actions';

@Injectable()
export class SynthesisReportEffects {
	getSynthesisReport = createEffect(() =>
		this._actions.pipe(
			ofType(SynthesisReportAction.getSynthesisReport),
			withLatestFrom(
				this._store.pipe(select(MyRestaurantsSelectors.getSelectedRestaurantsIds)),
				this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId)),
				this._store.pipe(select(MyRestaurantsSelectors.getIsLoading))
			),
			filter(([, selectedRestaurantsIds, defaultRestid, isLoading]) => !isLoading),
			mergeMap(([a, selectedRestaurantsIds, defaultRestid]) => {
				const restuarants = a.singleRestaurant ? [defaultRestid] : selectedRestaurantsIds;
				return this._sessionsHistoryApiClient
					.getSynthesisReportV1ReportsSynthesisGet(
						restuarants,
						null,
						null,
						formatDateISO(a.from.startOf(Range.Day).startOf(Range.Second).toISO()),
						formatDateISO(a.to.endOf(Range.Day).startOf(Range.Second).toISO())
					)
					.pipe(
						map(
							(data) => SynthesisReportAction.getSynthesisReportSuccess({ data }),
							catchError((error: HttpErrorResponse) => of(SynthesisReportAction.getSynthesisReportFail({ error })))
						)
					);
			})
		)
	);

	getSynthesis2Report = createEffect(() =>
		this._actions.pipe(
			ofType(SynthesisReportAction.getSynthesisVatReport),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getSelectedRestaurantsIds))),
			filter(([, selectedRestaurantsIds]) => !selectedRestaurantsIds.includes(undefined)),
			mergeMap(([a, selectedRestaurantsIds]) => {
				return this._sessionsHistoryApiClient
					.getSynthesis2ReportV1ReportsSynthesis2Get(
						selectedRestaurantsIds,
						null,
						null,
						formatDateISO(a.from.startOf(Range.Day).startOf(Range.Second).toISO()),
						formatDateISO(a.to.endOf(Range.Day).startOf(Range.Second).toISO())
					)
					.pipe(
						map(
							(data) => SynthesisReportAction.getSynthesisVatReportSuccess({ data }),
							catchError((error: HttpErrorResponse) => of(SynthesisReportAction.getSynthesisVatReportFail({ error })))
						)
					);
			})
		)
	);

	constructor(private _actions: Actions, private _sessionsHistoryApiClient: api.ReportsApiClient, private _store: Store<RootStoreState>, private fmService: FeatureMapService) {}
}
