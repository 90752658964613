import { NgModule } from '@angular/core';
import { TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoModule } from '@ngneat/transloco';

import { environment } from '../../environments/environment';
import { AVAILABLE_LANGUAGES, DEFAULT_LOCALE_LANGUAGE_KEY, FALLBACK_LANGUAGE_KEY } from '../core/entity';
import { TranslocoHttpLoader } from './transloco-http-loader.service';

@NgModule({
	exports: [TranslocoModule],
	providers: [
		{
			provide: TRANSLOCO_CONFIG,
			useValue: translocoConfig({
				availableLangs: AVAILABLE_LANGUAGES,
				defaultLang: DEFAULT_LOCALE_LANGUAGE_KEY,
				fallbackLang: FALLBACK_LANGUAGE_KEY,
				reRenderOnLangChange: true,
				prodMode: environment.production,
			}),
		},
		{ provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
	],
})
export class TranslocoRootModule {}
