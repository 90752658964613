import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
// import { MatSnackBar } from '@angular/material/snack-bar';
import * as api from '@dki/api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { ChannelOrders } from '../../../core/entity/interfaces';
import { formatDateISO } from '../../utils/functions';
import { MyRestaurantsSelectors } from '../my-restaurants';
import { RootStoreState } from '../state';
import * as HistoricStatisticsActions from './actions';

@Injectable()
export class HistoricStatisticsEffects {
	getOrdersByTime = createEffect(() =>
		this._actions.pipe(
			ofType(HistoricStatisticsActions.getOrdersByTime),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId))),
			mergeMap(([action, defaultRestaurantId]) =>
				this._dataApiClient.ordersByTimeV1DataOrdersByTimeGet(defaultRestaurantId, null, null, formatDateISO(action.from), formatDateISO(action.to)).pipe(
					map(
						(ordersByTime: api.OrdersByTime[]) => {
							return HistoricStatisticsActions.getOrdersByTimeSuccess({
								ordersByTime,
							});
						},
						catchError((error: HttpErrorResponse) => of(HistoricStatisticsActions.getOrdersByTimeFail({ error }))),
					),
				),
			),
		),
	);

	getOrdersByProduct = createEffect(() =>
		this._actions.pipe(
			ofType(HistoricStatisticsActions.getOrdersByProduct),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId))),
			mergeMap(([action, defaultRestaurantId]) =>
				this._dataApiClient.ordersByProductV1DataOrdersByProductGet([defaultRestaurantId], true, null, null, formatDateISO(action.from), formatDateISO(action.to)).pipe(
					map(
						(ordersByProduct: api.ProductOrders[]) => {
							return HistoricStatisticsActions.getOrdersByProductSuccess({
								ordersByProduct,
							});
						},
						catchError((error: HttpErrorResponse) => of(HistoricStatisticsActions.getOrdersByProductFail({ error }))),
					),
				),
			),
		),
	);

	getAverageOrdersAtCurrentTime = createEffect(() =>
		this._actions.pipe(
			ofType(HistoricStatisticsActions.getAverageOrdersAtCurrentTime),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId))),
			mergeMap(([action, defaultRestaurantId]) =>
				this._dataApiClient.averageOrdersByTimeV1DataAverageOrdersByTimeGet(defaultRestaurantId, true, null, null, formatDateISO(action.from), formatDateISO(action.to)).pipe(
					map(
						(averageOrdersAtCurrentTime: api.OrdersByTime[]) => {
							return HistoricStatisticsActions.getAverageOrdersAtCurrentTimeSuccess({
								averageOrdersAtCurrentTime,
							});
						},
						catchError((error: HttpErrorResponse) =>
							of(
								HistoricStatisticsActions.getAverageOrdersAtCurrentTimelFail({
									error,
								}),
							),
						),
					),
				),
			),
		),
	);

	getOrdersByChannel = createEffect(() =>
		this._actions.pipe(
			ofType(HistoricStatisticsActions.getOrdersByChannel),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId))),
			mergeMap(([action, defaultRestaurantId]) =>
				this._dataApiClient.ordersByChannelV1DataOrdersByChannelGet(defaultRestaurantId, null, null, formatDateISO(action.from), formatDateISO(action.to)).pipe(
					map(
						(ordersByChannel: ChannelOrders[]) => {
							return HistoricStatisticsActions.getOrdersByChannelSuccess({
								ordersByChannel,
							});
						},
						catchError((error: HttpErrorResponse) => of(HistoricStatisticsActions.getOrdersByChannelFail({ error }))),
					),
				),
			),
		),
	);

	getOrdersByChannelWithAverages = createEffect(() =>
		this._actions.pipe(
			ofType(HistoricStatisticsActions.getOrdersByChannelWithAverages),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId))),
			mergeMap(([action, defaultRestaurantId]) =>
				this._dataApiClient
					.ordersByChannelWithAveragesV1DataOrdersByChannelWithAveragesGet(defaultRestaurantId, null, null, formatDateISO(action.from), formatDateISO(action.to))
					.pipe(
						map(
							(ordersByChannelWithAverages: api.ChannelOrdersWithAverages[]) => {
								return HistoricStatisticsActions.getOrdersByChannelWithAveragesSuccess({
									ordersByChannelWithAverages,
								});
							},
							catchError((error: HttpErrorResponse) =>
								of(
									HistoricStatisticsActions.getOrdersByChannelWithAveragesFail({
										error,
									}),
								),
							),
						),
					),
			),
		),
	);

	getKisokSos = createEffect(() =>
		this._actions.pipe(
			ofType(HistoricStatisticsActions.getKioskSos),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId))),
			mergeMap(([action, defaultRestaurantId]) =>
				this._dataApiClient.sosKioskByTimeV1DataSosByTimeKioskGet(defaultRestaurantId, 'hour', null, null, formatDateISO(action.from), formatDateISO(action.to)).pipe(
					map(
						(sos: api.SosDriveByTime[]) => {
							const sosToDayparts = this._convertSosToDayparts(sos);

							return HistoricStatisticsActions.getKioskSosSuccess({
								sos: sosToDayparts,
							});
						},
						catchError((error: HttpErrorResponse) =>
							of(
								HistoricStatisticsActions.getKioskSosFail({
									error,
								}),
							),
						),
					),
				),
			),
		),
	);

	getSos = createEffect(() =>
		this._actions.pipe(
			ofType(HistoricStatisticsActions.getSos),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurantId))),
			mergeMap(([action, defaultRestaurantId]) =>
				this._dataApiClient.sosDriveByTimeV1DataSosByTimeDriveGet(defaultRestaurantId, 'hour', null, null, formatDateISO(action.from), formatDateISO(action.to)).pipe(
					map(
						(sos: api.SosDriveByTime[]) => {
							const sosToDayparts = this._convertSosToDayparts(sos);

							return HistoricStatisticsActions.getSosSuccess({
								sos: sosToDayparts,
							});
						},
						catchError((error: HttpErrorResponse) =>
							of(
								HistoricStatisticsActions.getSosFail({
									error,
								}),
							),
						),
					),
				),
			),
		),
	);

	private _convertSosToDayparts(sos: api.SosDriveByTime[]): api.SosDriveByTime[] {
		const dummySosObject = {
			mean_sos_waittime: 0,
			mean_sos_ordertime: 0,
			mean_sos_linetime: 0,
			mean_sos_paytime: 0,
			mean_sos_parktime: 0,
			mean_sos_preparationtime: 0,
			mean_sos_hardservetime: 0,
			mean_sos_servetime: 0,
			timestamp: '',
		};

		const reduceSosItems = (itemA: api.SosDriveByTime, itemB: api.SosDriveByTime) => {
			return {
				mean_sos_waittime: (itemA.mean_sos_waittime ? itemA.mean_sos_waittime : 0) + (itemB.mean_sos_waittime ? itemB.mean_sos_waittime : 0),
				mean_sos_ordertime: (itemA.mean_sos_ordertime ? itemA.mean_sos_ordertime : 0) + (itemB.mean_sos_ordertime ? itemB.mean_sos_ordertime : 0),
				mean_sos_linetime: (itemA.mean_sos_linetime ? itemA.mean_sos_linetime : 0) + (itemB.mean_sos_linetime ? itemB.mean_sos_linetime : 0),
				mean_sos_paytime: (itemA.mean_sos_paytime ? itemA.mean_sos_paytime : 0) + (itemB.mean_sos_paytime ? itemB.mean_sos_paytime : 0),
				mean_sos_parktime: (itemA.mean_sos_parktime ? itemA.mean_sos_parktime : 0) + (itemB.mean_sos_parktime ? itemB.mean_sos_parktime : 0),
				mean_sos_preparationtime: (itemA.mean_sos_preparationtime ? itemA.mean_sos_preparationtime : 0) + (itemB.mean_sos_preparationtime ? itemB.mean_sos_preparationtime : 0),
				mean_sos_hardservetime: (itemA.mean_sos_hardservetime ? itemA.mean_sos_hardservetime : 0) + (itemB.mean_sos_hardservetime ? itemB.mean_sos_hardservetime : 0),
				mean_sos_servetime: (itemA.mean_sos_servetime ? itemA.mean_sos_servetime : 0) + (itemB.mean_sos_servetime ? itemB.mean_sos_servetime : 0),
				timestamp: '',
			};
		};

		const calculateAverage = (agregated: api.SosDriveByTime, numberOfOriginalItems: number) => {
			return {
				mean_sos_waittime: agregated.mean_sos_waittime > 0 ? Math.round((agregated.mean_sos_waittime / numberOfOriginalItems) * 10) / 10 : 0,
				mean_sos_ordertime: agregated.mean_sos_ordertime > 0 ? Math.round((agregated.mean_sos_ordertime / numberOfOriginalItems) * 10) / 10 : 0,
				mean_sos_linetime: agregated.mean_sos_linetime > 0 ? Math.round((agregated.mean_sos_linetime / numberOfOriginalItems) * 10) / 10 : 0,
				mean_sos_paytime: agregated.mean_sos_paytime > 0 ? Math.round((agregated.mean_sos_paytime / numberOfOriginalItems) * 10) / 10 : 0,
				mean_sos_parktime: agregated.mean_sos_parktime > 0 ? Math.round((agregated.mean_sos_parktime / numberOfOriginalItems) * 10) / 10 : 0,
				mean_sos_preparationtime: agregated.mean_sos_preparationtime > 0 ? Math.round((agregated.mean_sos_preparationtime / numberOfOriginalItems) * 10) / 10 : 0,
				mean_sos_hardservetime: agregated.mean_sos_hardservetime > 0 ? Math.round((agregated.mean_sos_hardservetime / numberOfOriginalItems) * 10) / 10 : 0,
				mean_sos_servetime: agregated.mean_sos_servetime > 0 ? Math.round((agregated.mean_sos_servetime / numberOfOriginalItems) * 10) / 10 : 0,
				timestamp: '',
			};
		};

		const consolidateValues = (sosItems: api.SosDriveByTime[], label: string) => {
			const agregation = sosItems.length > 0 ? sosItems.reduce(reduceSosItems) : Object.assign({}, dummySosObject);
			const average = calculateAverage(agregation, sosItems.length);
			return {
				daypart: label,
				...average,
			};
		};

		const morningItems = sos.filter((item) => {
			const dateFromTimestamp = DateTime.fromISO(item.timestamp);
			return dateFromTimestamp.hour >= 7 && dateFromTimestamp.hour < 11;
		});
		const morning = consolidateValues(morningItems, 'morning');

		const lunchItems = sos.filter((item) => {
			const dateFromTimestamp = DateTime.fromISO(item.timestamp);
			return dateFromTimestamp.hour >= 11 && dateFromTimestamp.hour < 15;
		});
		const lunch = consolidateValues(lunchItems, 'lunch');

		const afternoonItems = sos.filter((item) => {
			const dateFromTimestamp = DateTime.fromISO(item.timestamp);
			return dateFromTimestamp.hour >= 15 && dateFromTimestamp.hour < 18;
		});
		const afternoon = consolidateValues(afternoonItems, 'afternoon');

		const dinnerItems = sos.filter((item) => {
			const dateFromTimestamp = DateTime.fromISO(item.timestamp);
			return dateFromTimestamp.hour >= 18 && dateFromTimestamp.hour < 22;
		});
		const dinner = consolidateValues(dinnerItems, 'dinner');

		// const eveningItems = sos.filter((item) => {
		// 	const dateFromTimestamp = DateTime.fromISO(item.timestamp);
		// 	return dateFromTimestamp.hour >= 21 && dateFromTimestamp.hour < 23;
		// });
		// const evening = consolidateValues(eveningItems, 'evening');

		const nightItems = sos.filter((item) => {
			const dateFromTimestamp = DateTime.fromISO(item.timestamp);
			return dateFromTimestamp.hour >= 22 || dateFromTimestamp.hour < 4;
		});
		const night = consolidateValues(nightItems, 'night');

		// const peakItems = sos.filter((item) => {
		// 	const dateFromTimestamp = DateTime.fromISO(item.timestamp);

		// 	return dateFromTimestamp.hour >= 4 && dateFromTimestamp.hour < 7;
		// });
		// const peak = consolidateValues(peakItems, 'peak');

		return [
			morning,
			lunch,
			afternoon,
			dinner,
			// evening,
			night,
			// peak
		];
	}

	constructor(
		private _actions: Actions<Action>,
		private _dataApiClient: api.DataApiClient,
		private _store: Store<RootStoreState>, // private _snackBar: MatSnackBar,
	) {}
}
