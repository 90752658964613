import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { ConfigurationService } from '@merim/utils';
import { Observable, of } from 'rxjs';

import { FeatureMapService } from '../feature-map/feature-map.service';

@Injectable({ providedIn: 'root' })
export class FeatureGuard implements CanActivate {
	constructor(private readonly configurationService: ConfigurationService, private readonly fs: FeatureMapService) {}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		const hasFeatureFlag = route.data.canActivateFeatureFlag;
		if (hasFeatureFlag) {
			switch (hasFeatureFlag) {
				case 'reports':
					return of(this.fs.featureMap.sidebar.sales);
				case 'history':
					return of(this.fs.featureMap.sidebar.history);
				case 'dtt':
					return of(this.fs.featureMap.sidebar.dtt);
				case 'accounting':
					return of(this.fs.featureMap.sidebar.accounting);
				default:
					return of(this.configurationService.hasFeature(route.data.canActivateFeatureFlag, false));
			}
		} else {
			return of(true);
		}
	}
}
