/* eslint-disable @typescript-eslint/no-unused-vars */
import { Action, createReducer, on } from '@ngrx/store';

import * as Actions from './actions';
import { SESSION_HISTORY_INITIAL_STATE, SessionHistoryState } from './state';

export const sessionsHistoryReducer = createReducer<SessionHistoryState, Action>(
	SESSION_HISTORY_INITIAL_STATE,
	on(Actions.getSessionHistory, (state, _action) => ({ ...state, isLoading: true, error: undefined, from: _action.from, to: _action.to })),

	on(Actions.getSessionHistoryFail, (state, _action) => ({
		...state,
		isLoading: false,
		error: true,
	})),

	on(Actions.getSessionHistorySuccess, (state, action) => ({
		...state,
		data: action.data,
		isLoading: false,
		error: false,
	})),
);
