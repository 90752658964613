<ng-container *ngIf="showLabel">
    <span>
        version:
    </span>
    
    <span>
        &nbsp;
    </span>
</ng-container>

<span>
    {{ $currentAppVesrion | async }}
</span>
