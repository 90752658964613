import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as api from '@dki/api-client';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { DateTime } from 'luxon';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { MyRestaurantsSelectors } from '../my-restaurants';
import { RootStoreState } from '../state';
import * as PerformanceActions from './actions';

@Injectable()
export class PerformanceEffects {
	fetchForecastReport = createEffect(() =>
		this._actions.pipe(
			ofType(PerformanceActions.getForecasting),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurant)), this._store.pipe(select(MyRestaurantsSelectors.isLoadingPersistedRestaurant))),
			filter(([a, b, loading]) => {
				return !loading;
			}),
			mergeMap(([, defaultRestaurantId]) => {
				const today = DateTime.now();
				const currentBusinessDay = today.hour < 6 ? today.minus({ day: 1 }) : today;
				return this._fcastingpiClient
					.productsForecastV1ForecastingProductsForecastGet(defaultRestaurantId.id, 15, null, null, null, null, currentBusinessDay.toISODate(), currentBusinessDay.toISODate())
					.pipe(
						map((forecast) => {
							return PerformanceActions.getForecastingSuccess({ forecast });
						}),
						catchError((error: HttpErrorResponse) => of(PerformanceActions.getForecastingFail({ error }))),
					);
			}),
		),
	);

	fetchHourlySales = createEffect(() =>
		this._actions.pipe(
			ofType(PerformanceActions.getOrdersByTimeAndChannel),
			withLatestFrom(this._store.pipe(select(MyRestaurantsSelectors.getDefaultRestaurant)), this._store.pipe(select(MyRestaurantsSelectors.isLoadingPersistedRestaurant))),
			filter(([, , loading]) => {
				return !loading;
			}),
			mergeMap(([a, defaultRestaurant]) => {
				const today = DateTime.now();
				const currentBusinessDay = today.hour < 6 ? today.minus({ day: 1 }) : today;
				if (a.current) {
					// querying for current day and year
					return this._dataApiClient
						.ordersByTimeAndChannelV1DataOrdersByTimeAndChannelGet([defaultRestaurant.id], null, null, currentBusinessDay.toISODate(), currentBusinessDay.toISODate())
						.pipe(
							map((hourlySales) => {
								return PerformanceActions.getOrdersByTimeAndChannelSuccess({ hourlySales });
							}),
							catchError((error: HttpErrorResponse) => of(PerformanceActions.getOrdersByTimeAndChannelFail({ error }))),
						);
				} else {
					const lastYear = currentBusinessDay.minus({ year: 1 });
					const bDayFrom = lastYear.startOf('day').toFormat('yyyy-MM-dd');
					const bDayto = lastYear.endOf('day').toFormat('yyyy-MM-dd');
					return this._reportsApiClient.dailySalesTotalRevenueV1ReportsDailySalesTotalRevenueGet([defaultRestaurant.id], null, null, bDayFrom, bDayto).pipe(
						map((hourlySales) => {
							return PerformanceActions.getPasOrdersByTimeAndChannelSuccess({ hourlySales });
						}),
						catchError((error: HttpErrorResponse) => of(PerformanceActions.getOrdersByTimeAndChannelFail({ error }))),
					);
				}
			}),
		),
	);

	constructor(
		private _actions: Actions<Action>,
		private _fcastingpiClient: api.ForecastingApiClient,
		private _dataApiClient: api.DataApiClient,
		private _reportsApiClient: api.ReportsApiClient,
		private _store: Store<RootStoreState>,
	) {}
}
