import { HttpErrorResponse } from '@angular/common/http';
import * as api from '@dki/api-client';
import { createAction, props } from '@ngrx/store';

import { PERFORMANCE_KEY } from './state';

export const getForecasting = createAction(`[${PERFORMANCE_KEY}] -> Get Forecasting`);
export const getForecastingSuccess = createAction(`[${PERFORMANCE_KEY}] -> Get Forecasting Success`, props<{ forecast: { [key: string]: api.BusinessDayProductsForecast } }>());
export const getForecastingFail = createAction(`[${PERFORMANCE_KEY}] -> Get Forecasting Fail`, props<{ error: HttpErrorResponse | null }>());

export const getOrdersByTimeAndChannel = createAction(`[${PERFORMANCE_KEY}] -> Get Hourly sales`, props<{ current: boolean }>());
export const getOrdersByTimeAndChannelSuccess = createAction(`[${PERFORMANCE_KEY} SUCCESS] -> Get Hourly sales`, props<{ hourlySales: api.HourlySales }>());
export const getPasOrdersByTimeAndChannelSuccess = createAction(`[${PERFORMANCE_KEY} SUCCESS] -> Get Past Hourly sales`, props<{ hourlySales: api.DailyRevenues }>());
export const getOrdersByTimeAndChannelFail = createAction(`[${PERFORMANCE_KEY} FAIL] -> Get Hourly sales`, props<{ error: HttpErrorResponse | null }>());
